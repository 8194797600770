import React from "react";

import { Link } from "react-router-dom";
import { useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    description: "",
    body: "some message",
  });

  const [formErrors, setFormErrors] = useState({
    email: "",
    subject: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear the validation error when the user starts typing in a field
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let valid = true;
    const newFormErrors = {};

    // Basic email validation
    if (!formData.email || !formData.email.includes("@")) {
      newFormErrors.email = "Please enter a valid email address.";
      valid = false;
    }

    // Subject should not be empty
    if (!formData.subject) {
      newFormErrors.subject = "Subject is required.";
      valid = false;
    }

    if (!formData.description) {
      newFormErrors.subject = "Description is required.";
      valid = false;
    }

    setFormErrors(newFormErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const apiUrl = "https://admin.levelitapp.net/api/enquiries";

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      };

      try {
        const response = await fetch(apiUrl, requestOptions);

        if (response.ok) {
          // Request was successful, you can handle the success here
          console.log("Message sent successfully!");
          toast.success("Your message has been successfully sent!");

          // Setting initial state on success
          setFormData({
            email: "",
            subject: "",
            description: "",
            body: "some message",
          });

        } else {
          // Request failed, handle the error here
          console.error("Failed to send message");
          toast.error("Failed to send message.");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to send message.");
      }
    }
  };
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <NavBar />
      <section class="bg-white dark:bg-neutral-800 ">
        <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 class="mb-4 text-4xl tracking-wide font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
          <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
            Reach out to us and let your voice be heard - We value your feedback, suggestions, and concerns!".
          </p>
          <form onSubmit={handleSubmit} className="space-y-8">
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Your email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`${formErrors.email ? "border-red-500" : ""
                  } shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-main-500 focus:border-main-500 block w-full p-2.5 dark:bg-neutral-600 dark:border-neutral-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-main-500 dark:focus:border-main-500 dark:shadow-sm-light`}
                placeholder="user@example.com"
                required
              />
              {formErrors.email && <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>}
            </div>
            <div>
              <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className={`${formErrors.subject ? "border-red-500" : ""
                  } block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-main-500 focus:border-main-500 dark:bg-neutral-600 dark:border-neutral-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-main-500 dark:focus:border-main-500 dark:shadow-sm-light`}
                placeholder="Let us know how we can help you"
                required
              />
              {formErrors.subject && <p className="text-red-500 text-sm mt-1">{formErrors.subject}</p>}
            </div>
            <div class="sm:col-span-2">
              <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                Your message
              </label>
              <textarea
                type="text"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className={`${formErrors.description ? "border-red-500" : ""
                  } block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-main-500 focus:border-main-500 dark:bg-neutral-600 dark:border-neutral-400 dark:placeholder-gray-400 dark:text-white dark:focus:ring-main-500 dark:focus:border-main-500 dark:shadow-sm-light`}
                placeholder="Please provide more information"
                rows={3}
                required
              ></textarea>
            </div>
            <div class="flex justify-end">
              <button
                type="submit"
                className="py-3 px-5 text-sm font-medium bg-secondary text-white rounded-lg bg-main-700 sm:w-fit hover:bg-main-800 focus:ring-4 focus:outline-none focus:ring-main-300 dark:bg-secondary dark:text-white dark:hover:bg-main-700 dark:focus:ring-main-800"
              >
                Send message
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
