import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import mainImage from "./assets/img.png";
import appImage1 from "./assets/app-image1.jpg";
import appImage2 from "./assets/app-image2.jpg";
import appImage3 from "./assets/app-image3.jpg";
import appImage4 from "./assets/app-image4.jpg";
import appImage5 from "./assets/app-image5.jpg";
import appImage6 from "./assets/app-image6.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faPaintBrush, faGift, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import BasicGuide from "./BasicGuide";
import ImageCarousel from "./ImageCarousel";

function LandingPage() {
  const images = [
    appImage1,
    appImage2,
    appImage3,
    appImage4,
    appImage5,
    appImage6,

    // Add more image URLs here
  ];
  return (
    <div>
      {/* header */}
      {/* nav*/}
      <NavBar />

      {/* body */}
      <div className="main-wrapper max-w-7xl mx-auto px-4 sm:px-6 flex flex-col gap-8">
        {/* main Image */}
        <section className="grid lg:grid-cols-2 grid-cols-1 gap-3" id="home">
          <div className="flex flex-col gap-2">
            <div className="text-8xl font-bold dark:text-white">
              IT'S TIME <p>TO</p> <p className="text-secondary">LEVELIT</p>
            </div>
            <p className="text-2xl text-gray-700 font-semibold mt-4 dark:text-slate-50">Join Contest and Earn Rewards</p>
            <div className="text-slate-500 dark:text-slate-200">
              <p>
                <span className="text-secondary font-bold">LEVELIT </span>is a social media and competition app that rewards content creators instantly.{" "}
              </p>
              <p className="">Share your creativity, compete with fellow creators, and enjoy instant rewards for your amazing content.</p>
            </div>
            <p className="text-gray-500 dark:text-gray-400">Download now and let your talent shine!</p>

            <div className="flex gap-3">
              {/* <button>
                <div className="flex mt-3 w-48 h-14 bg-black text-white rounded-xl items-center justify-center">
                  <div class="mr-3">
                    <svg viewBox="0 0 384 512" width="30">
                      <path
                        fill="currentColor"
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                      />
                    </svg>
                  </div>
                  <div>
                    <div class="text-xs">Download on the</div>
                    <div class="text-2xl font-semibold font-sans -mt-1">App Store</div>
                  </div>
                </div>
              </button> */}
              <button>
                <div class="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center">
                  <div class="mr-3">
                    <svg viewBox="30 336.7 120.9 129.2" width="30">
                      <path
                        fill="#FFD400"
                        d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
                      />
                      <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                      <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                      <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                    </svg>
                  </div>
                  <div>
                    <div class="text-xs">GET IT ON</div>
                    <div class="text-xl font-semibold font-sans -mt-1">Google Play</div>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="">
            <img alt="main" src={mainImage} className="object-cover h-full" />
          </div>
        </section>
        {/* how it works */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-8">
          <div className="flex justify-center">
            {/* <img alt="main" src={appImage} className="object-cover w-80 rounded-3xl" /> */}
            <ImageCarousel images={images} className="" />
          </div>
          <div className="flex flex-col gap-6 justify-center">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div className="p-3 bg-white rounded-lg shadow dark:bg-neutral-800 flex gap-3">
                <FontAwesomeIcon icon={faPaintBrush} className="text-secondary text-3xl dark:text-secondary mt-2" />
                <div>
                  <h5 className="mb-1 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                    Showcase Your <span className="text-secondary">Creativity</span>
                  </h5>
                  <p className="font-normal text-gray-500 dark:text-gray-400">
                    Share your captivating stories, stunning photos, and engaging videos with LevelIt
                  </p>
                </div>
              </div>
              <div className="p-3 bg-white rounded-lg shadow dark:bg-neutral-800 flex gap-3">
                <FontAwesomeIcon icon={faGift} className="text-secondary text-3xl dark:text-secondary mt-2" />
                <div>
                  <h5 className="mb-1 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                    Instant <span className="text-secondary">Rewards</span>
                  </h5>
                  <p className="font-normal text-gray-500 dark:text-gray-400">
                    Instant rewards, no waiting. LevelIt lets you reap the benefits of your hard work instantly
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div className="p-3 bg-white rounded-lg shadow dark:bg-neutral-800 flex gap-3">
                <FontAwesomeIcon icon={faTrophy} className="text-secondary text-3xl dark:text-secondary mt-2" />
                <div>
                  <h5 className="mb-1 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white ">
                    Thrilling <span className="text-secondary">Competition</span>
                  </h5>
                  <p className="font-normal text-gray-500 dark:text-gray-400">Unleash your creativity, compete, and win big!</p>
                </div>
              </div>
              <div className="p-3 bg-white rounded-lg shadow dark:bg-neutral-800 flex gap-3">
                <FontAwesomeIcon icon={faLightbulb} className="text-secondary text-3xl dark:text-secondary mt-2" />
                <div>
                  <h5 className="mb-1 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                    Unleash Your <span className="text-secondary">Potential</span>
                  </h5>
                  <p className="font-normal text-gray-500 dark:text-gray-400">Unleash your creativity. Explore, experiment, and discover your true potential</p>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
        <div>{/* image */}</div>
        <section id="how-it-works" className="rounded-sm">
          <BasicGuide />
        </section>
        <Footer />
      </div>
    </div>
  );
}
export default LandingPage;
