import React, { useState, useEffect } from "react";

const DarkModeToggle = () => {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.toggle("dark", darkMode);
  }, [darkMode]);

  const handleToggle = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="flex items-center justify-end p-4">
      <label htmlFor="dark-mode-toggle" className="mr-2">
        Dark Mode
      </label>
      <input
        type="checkbox"
        id="dark-mode-toggle"
        className="w-8 h-4 rounded-full bg-gray-300 dark:bg-gray-600 focus:outline-none"
        checked={darkMode}
        onChange={handleToggle}
      />
    </div>
  );
};

export default DarkModeToggle;
