import React from "react";
import logo from "./assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import NavBar from "./NavBar";
import Footer from "./Footer";

const TermCondition = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <NavBar />
      <div className="p-4">
        <div className=" bg-white rounded-md shadow-md p-6 dark:bg-neutral-800 dark:text-white">
          <h1 className="text-2xl font-bold mb-4">Levelit App Terms and Conditions</h1>
          <p className="text-gray-700 dark:text-white">
            These terms and conditions ("Terms") govern your access to and use of the Levelit app ("App"), owned by Bisht Vision Pvt Ltd ("Company"). By using
            the Levelit App, you agree to comply with these Terms. If you do not agree with any part of these Terms, please refrain from using the App.
          </p>

          <h2 className="text-lg font-bold mt-6 mb-2">1. Content Guidelines</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">Users of the Levelit App must adhere to the following content guidelines:</span>
              <ul className="list-disc list-inside ml-4">
                <li>No unrelated content: Users should only submit content that is relevant to the specified genres and contests available on the App.</li>
                <li>
                  No nudity or explicit content: Users must not upload, share, or promote any form of nudity, sexually explicit material, or any content
                  violating applicable laws and regulations.
                </li>
                <li>
                  No plagiarism: Users must ensure that all submitted content is original and does not infringe upon any third-party copyrights or intellectual
                  property rights.
                </li>
              </ul>
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">2. Account Creation and Usage</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                To participate in contests and access all features of the Levelit App, users are required to create an account.
              </span>
              <ol className="list-decimal list-inside ml-4">
                <li>Users must provide accurate and complete information during the registration process.</li>
                <li>
                  Users are solely responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their
                  account.
                </li>
                <li>Users must not share their account credentials with any third parties or allow unauthorized access to their accounts.</li>
              </ol>
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">3. Contest Participation</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                Users may participate in contests available on the Levelit App by paying the designated entry fees, where applicable.
              </span>
              <ol className="list-decimal list-inside ml-4">
                <li>
                  The number of winners and the prize distribution will be determined by the pool amount of the contest, as specified for each individual
                  contest.
                </li>
                <li>
                  The winners will be decided based on user voting. However, the Company reserves the right to incorporate other factors, such as expert judging
                  or additional criteria, to ensure fair competition.
                </li>
                <li>
                  Any attempt to manipulate the voting system or engage in fraudulent activities may result in disqualification from the contest and suspension
                  or termination of the user's account.
                </li>
              </ol>
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">4. Intellectual Property</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                By submitting content on the Levelit App, users grant the Company a non-exclusive, worldwide, royalty-free license to use, reproduce, modify,
                adapt, publish, translate, distribute, and display the content for the purposes of operating and promoting the App.
              </span>
              <ol className="list-decimal list-inside ml-4">
                <li>
                  Users represent and warrant that they own or have obtained all necessary rights, licenses, and permissions to grant the above license to the
                  Company.
                </li>
              </ol>
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">5. Prohibited Conduct</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">Users must not engage in any of the following prohibited activities while using the Levelit App:</span>
              <ul className="list-disc list-inside ml-4">
                <li>Violating any applicable laws, regulations, or third-party rights.</li>
                <li>Uploading or sharing content that is defamatory, abusive, harassing, or harmful to others.</li>
                <li>Attempting to gain unauthorized access to the App or interfering with its proper functioning.</li>
                <li>Engaging in any fraudulent activities, including but not limited to submitting false information, manipulating votes, or using bots.</li>
              </ul>
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">6. Disclaimer and Limitation of Liability</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                The Levelit App is provided on an "as is" and "as available" basis. The Company does not warrant or guarantee the accuracy, completeness, or
                reliability of any content available on the App.
              </span>
            </li>
            <li className="mb-2">
              The Company shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the
              use of the Levelit App.
            </li>
            <li className="mb-2">The Company does not endorse or take responsibility for any user-generated content or any opinions expressed therein.</li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">7. Modification of the Terms</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                The Company reserves the right to modify, update, or replace these Terms at any time. Any changes will be effective immediately upon posting on
                the Levelit App.
              </span>
            </li>
            <li className="mb-2">
              It is the responsibility of users to review these Terms periodically to stay informed of any modifications. Continued use of the App after any
              changes constitutes acceptance of the revised Terms.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">8. Termination</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                The Company reserves the right to suspend or terminate access to the Levelit App at any time and for any reason without prior notice.
              </span>
            </li>
            <li className="mb-2">Users may also terminate their account and cease using the App at any time.</li>
            <li className="mb-2">
              Upon termination, all rights and licenses granted to users will immediately cease, and they must discontinue any use of the App.
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">9. Indemnification</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                Users agree to indemnify and hold the Company and its affiliates, directors, officers, employees, and agents harmless from any claims, damages,
                liabilities, losses, or expenses arising out of or in connection with their use of the Levelit App or any violation of these Terms.
              </span>
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">10. Governing Law and Jurisdiction</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.
              </span>
            </li>
            <li className="mb-2">
              Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">11. Severability</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such provision shall be deemed severed from
                these Terms without affecting the enforceability of the remaining provisions.
              </span>
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">12. Privacy and Data Protection</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                The Company respects the privacy of its users. Please refer to the Privacy Policy available on the Levelit App for information on how user data
                is collected, used, and protected.
              </span>
            </li>
            <li className="mb-2">
              By using the Levelit App, you consent to the collection, storage, and processing of your personal information as outlined in the Privacy Policy.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">13. Age Restrictions</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                The Levelit App is intended for individuals who are at least 14 years old. By using the App, you confirm that you meet the minimum age
                requirement.
              </span>
            </li>
            <li className="mb-2">
              If you are below the minimum age, please discontinue using the Levelit App and seek parental or guardian consent before accessing the services.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">14. Third-Party Services and Content</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                The Levelit App may contain links to third-party websites, services, or content that are not owned or controlled by the Company.
              </span>
            </li>
            <li className="mb-2">
              The Company assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services.
            </li>
            <li className="mb-2">
              Users acknowledge and agree that the Company shall not be liable for any damages or losses incurred as a result of their interactions with
              third-party websites, services, or content.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">15. Copyright Complaints</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                The Company respects the intellectual property rights of others. If you believe that any content on the Levelit App infringes upon your
                copyrights, please submit a written notification to the Company's designated copyright agent.
              </span>
            </li>
            <li className="mb-2">
              The notification should include detailed information about the copyrighted work and evidence of your ownership, along with your contact
              information.
            </li>
            <li className="mb-2">The Company will investigate the complaint and take appropriate actions as required by applicable law.</li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">16. Feedback and Suggestions</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">The Company welcomes user feedback, suggestions, and ideas regarding the Levelit App.</span>
            </li>
            <li className="mb-2">
              By submitting feedback or suggestions, you grant the Company the right to use, modify, and implement such feedback without any obligation to
              compensate you.
            </li>
            <li className="mb-2">
              The Company shall have no liability for any similarities between its services and any future products or developments resulting from user
              feedback.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">17. Survival</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                The provisions of these Terms that, by their nature, are intended to survive termination or expiration shall continue to be binding upon the
                users, including but not limited to the provisions related to intellectual property, indemnification, and limitation of liability.
              </span>
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">18. Payment Integration</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                The Levelit App may include payment integration functionality to facilitate entry fee payments for contests.
              </span>
            </li>
            <li className="mb-2">Users agree to provide accurate and valid payment information when making payments through the app.</li>
            <li className="mb-2">
              The Company utilizes third-party payment processors to handle transactions securely. Users may be subject to the terms and conditions of these
              payment processors when making payments.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">19. Voting Process</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                Users of the Levelit App have the opportunity to participate in the voting process to determine contest winners.
              </span>
            </li>
            <li className="mb-2">Each user is entitled to rate the content using the rating meter bar, which scales from 1 to 5.</li>
            <li className="mb-2">The content with the highest rating points, calculated based on user ratings, will be declared the winner.</li>
            <li className="mb-2">
              The Company reserves the right to implement measures to prevent fraudulent voting activities, including the use of bots or solicitation of votes.
            </li>
            <li className="mb-2">
              Any user found to be engaging in manipulative voting practices may be disqualified from the contest and subject to account suspension or
              termination.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">20. Account Deletion</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">Users have the option to delete their Levelit App account at any time.</span>
            </li>
            <li className="mb-2">To delete your account, please contact our support team at [insert contact email].</li>
            <li className="mb-2">
              Upon account deletion, all personal information associated with the account will be permanently deleted from our systems, except as required by
              applicable law or for legitimate business purposes.
            </li>
            <li className="mb-2">
              Please note that the deletion of your account is irreversible, and you will lose access to all the features and content associated with your
              account.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">21. Entire Agreement</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">
                These Terms constitute the entire agreement between the users and the Company regarding the use of the Levelit App and supersede any prior or
                contemporaneous agreements, understandings, or representations, whether oral or written.
              </span>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermCondition;
