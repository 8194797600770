import { faPlus, faTrophy, faUpload, faVoteYea } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
const BasicGuide = () => {
  return (
    <div className="dark:bg-neutral-800 min-h-screen flex flex-col justify-center items-center py-2 ">
      <h1 className="text-3xl font-bold dark:text-white mb-6 py-3 px-2">Discover, Engage, and Win!</h1>

      <div className="max-w-3xl w-full  rounded-lg shadow-lg p-8 mb-8">
        <div className="flex items-center mb-6">
          <div className="mr-4 mt-6">
            <FontAwesomeIcon icon={faPlus} className="text-neutral-400 w-8 h-8" />
          </div>
          <div>
            <h2 className="text-xl font-bold dark:text-white mb-1">Step 1: Join a Contest</h2>
            <p className="text-gray-400"> Get started by joining an exciting contest in your favorite category.</p>
          </div>
        </div>
        <div className="flex items-center mb-6">
          <div className="mr-4 mt-5">
            <FontAwesomeIcon icon={faUpload} className="text-secondary w-8 h-8" />
          </div>
          <div>
            <h2 className="text-xl font-bold dark:text-white mb-1">Step 2: Upload Your Content</h2>
            <p className="text-gray-400"> Showcase your talent by uploading your amazing content for the contest.</p>
          </div>
        </div>

        <div className="flex items-center mb-6">
          <div className="mr-4 mt-5">
            <FontAwesomeIcon icon={faVoteYea} className="text-green-500 w-8 h-8" />
          </div>
          <div>
            <h2 className="text-xl font-bold dark:text-white mb-1">Step 3: Get Votes and Reach the Top</h2>
            <p className="text-gray-400">Collect votes from the community and strive to be among the top contenders..</p>
          </div>
        </div>

        <div className="flex items-center">
          <div className="mr-4 mt-5">
            <FontAwesomeIcon icon={faTrophy} className="text-yellow-500 w-8 h-8" />
          </div>
          <div>
            <h2 className="text-xl font-bold dark:text-white mb-1">Step 4: Win Exciting Prizes</h2>
            <p className="text-gray-400">Reach for the stars! Winners will be rewarded with amazing prizes based on the community's verdict.</p>
          </div>
        </div>
      </div>

      <button className="bg-secondary hover:bg-secondary text-white font-bold py-2 px-4 rounded">Get Started</button>
    </div>
  );
};

export default BasicGuide;
