import React, { useState, useEffect } from "react";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 3000); // Slide every 3 seconds

    return () => clearInterval(interval);
  }, [images]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="relative">
      <div className="overflow-hidden w-full">
        <div
          className="flex transition-transform transform items-center justify-center"
          style={{
            width: `${100 * images.length}%`,
            transform: `translateX(-${(currentIndex / images.length) * 100}%)`,
          }}
        >
          {images.map((image, index) => (
            <div key={index} className="flex-shrink-0" style={{ width: `${100 / images.length}%` }}>
              <img src={image} alt={`Slide ${index}`} className="mx-auto rounded-3xl" />
            </div>
          ))}
        </div>
      </div>
      <div className="absolute top-1/2 left-4 transform -translate-y-1/2">
        <button className="text-2xl bg-transparent border-none text-white" onClick={prevSlide}>
          &#8592;
        </button>
      </div>
      <div className="absolute top-1/2 right-4 transform -translate-y-1/2">
        <button className="text-2xl bg-transparent border-none text-white" onClick={nextSlide}>
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default ImageCarousel;
