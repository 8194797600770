import React from "react";
import logo from "./assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import NavBar from "./NavBar";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <NavBar />
      <div className="p-4">
        <div className=" bg-white rounded-md shadow-md p-6 dark:bg-neutral-800 dark:text-white">
          <h1 className="text-2xl font-bold mb-4">Levelit App Privacy Policy</h1>
          <p className="text-gray-700 dark:text-white">
            This Privacy Policy describes how Bisht Vision Pvt Ltd ("Company") collects, uses, stores, and protects the personal information of users who access
            and use the Levelit app ("App"). By using the Levelit App, you consent to the practices described in this Privacy Policy.
          </p>

          <h2 className="text-lg font-bold mt-6 mb-2">1. Content Guidelines</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              <span className="font-semibold">Users of the Levelit App must adhere to the following content guidelines:</span>
              <ul className="list-disc list-inside ml-4">
                <li>No unrelated content: Users should only submit content that is relevant to the specified genres and contests available on the App.</li>
                <li>
                  No nudity or explicit content: Users must not upload, share, or promote any form of nudity, sexually explicit material, or any content
                  violating applicable laws and regulations.
                </li>
                <li>
                  No plagiarism: Users must ensure that all submitted content is original and does not infringe upon any third-party copyrights or intellectual
                  property rights.
                </li>
              </ul>
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">2. Information We Collect</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              Personal Information: When you create an account on the Levelit App, we may collect personal information, such as your name, email address,
              username, and any other information you provide voluntarily.
            </li>
            <li className="mb-2">
              User-Generated Content: The App allows you to submit and share content, including but not limited to images, videos, and text. This content may
              contain personal information, depending on your choices.
            </li>
            <li className="mb-2">
              Usage Data: We may collect information about how you interact with the Levelit App, including your device information, IP address, browser type,
              and operating system. This data is collected through cookies, log files, and similar technologies.
            </li>
            <li className="mb-2">
              Payment Information: If you choose to participate in paid contests, we may collect payment information, such as credit card details or other
              financial information. However, we do not store this information on our servers. Payment processing is handled by trusted third-party payment
              processors.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">3. Use of Information</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              We use the collected information for the following purposes:
              <ul className="list-disc list-inside ml-4">
                <li>To provide and maintain the Levelit App and its features.</li>
                <li>To verify your identity, process payments, and administer contests.</li>
                <li>To communicate with you regarding your account, contests, updates, and promotional offers.</li>
                <li>To personalize your experience on the App and improve our services.</li>
                <li>To analyze and monitor App usage patterns, perform data analytics, and enhance the performance and security of the App.</li>
                <li>To enforce our Terms and Conditions and protect the rights, safety, and property of users and the Company.</li>
              </ul>
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">4. Sharing of Information</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              We may share your personal information with third-party service providers, contractors, and business partners who assist us in providing the
              Levelit App and its services.
            </li>
            <li className="mb-2">
              We may disclose personal information if required by law, court order, or government regulation, or if we believe in good faith that such
              disclosure is necessary to protect our rights, safety, or property, or to respond to an emergency.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">5. Data Security</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
            </li>
            <li className="mb-2">
              While we strive to protect your personal information, please note that no method of transmission or storage over the internet is completely
              secure. Therefore, we cannot guarantee absolute security of your data.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">6. Data Retention</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law.
              We will securely delete or anonymize your personal information once it is no longer needed.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">7. Third-Party Links and Services</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              The Levelit App may contain links to third-party websites, services, or content that are not operated or controlled by the Company. This Privacy
              Policy does not apply to such third-party services. We recommend reviewing the privacy policies of those third parties before interacting with
              them.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">8. Children's Privacy</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              The Levelit App is not intended for children under the age of 14. We do not knowingly collect personal information from children. If you are a
              parent or guardian and believe that your child has provided personal information, please contact us, and we will promptly delete such information.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">9. Changes to the Privacy Policy</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              We may update this Privacy Policy from time to time to reflect changes in our data practices or legal requirements. We will notify you of any
              material changes by posting the updated Privacy Policy on the Levelit App or through other communication channels.
            </li>
            <li className="mb-2">
              It is your responsibility to review the Privacy Policy periodically and stay informed of any updates. Your continued use of the Levelit App after
              the changes signifies your acceptance of the revised Privacy Policy.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">10. Your Rights and Choices</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              You have the right to access, modify, or delete the personal information we hold about you, subject to any legal requirements or legitimate
              interests.
            </li>
            <li className="mb-2">
              You can update your account information and communication preferences by accessing the relevant settings in the Levelit App or by contacting us.
            </li>
            <li className="mb-2">
              You may choose to disable cookies through your browser settings. However, please note that certain features of the Levelit App may not function
              properly without cookies.
            </li>
          </ol>

          <h2 className="text-lg font-bold mt-6 mb-2">11. Contact Us</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at:
              <ul className="list-disc list-inside ml-4">
                <li>Company : Bisht Vision Pvt Ltd</li>
                <li>Address:</li>
                <li>Email: levelitofficiall@gmail.com</li>
              </ul>
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">12. Consent</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              By using the Levelit App, you consent to the collection, use, storage, and disclosure of your personal information as described in this Privacy
              Policy.
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">13. Intellectual Property</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              The Levelit App and its content, including but not limited to text, graphics, images, logos, trademarks, and software, are the intellectual
              property of the Company and protected by applicable intellectual property laws.
            </li>
            <li className="mb-2">
              Users are granted a limited, non-exclusive, non-transferable license to access and use the Levelit App solely for personal and non-commercial
              purposes. Any unauthorized use or reproduction of the App's intellectual property is strictly prohibited.
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">14. Modification of the App</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              The Company reserves the right to modify, suspend, or discontinue the Levelit App or any of its features at any time without prior notice.
            </li>
            <li className="mb-2">
              Users agree that the Company shall not be liable to them or any third party for any modifications, suspension, or discontinuation of the App.
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">15. Limited Liability</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              To the maximum extent permitted by applicable law, the Company and its affiliates, directors, officers, employees, or agents shall not be liable
              for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or use, arising out
              of or in connection with the use of the Levelit App.
            </li>
            <li className="mb-2">
              Users acknowledge that their use of the App is at their own risk and that the Company's liability for any claims arising out of or in connection
              with the App shall be limited to the amount of fees paid by the user, if any, to the Company during the twelve (12) months preceding the claim.
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">16. User Content</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              By submitting content to the Levelit App, including but not limited to images, videos, and text, users retain ownership of their content.
            </li>
            <li className="mb-2">
              Users grant the Company a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, and display their content for
              the purposes of operating, promoting, and improving the Levelit App.
            </li>
            <li className="mb-2">
              Users represent and warrant that they have all necessary rights and permissions to grant the license mentioned above and that their content does
              not infringe upon the intellectual property or privacy rights of any third party.
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">17. Governing Law</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              These Terms and Conditions and any dispute or claim arising out of or in connection with the Levelit App shall be governed by and construed in
              accordance with the laws of [Jurisdiction].
            </li>
            <li className="mb-2">
              Any legal action or proceeding arising out of or relating to these Terms shall be brought exclusively in the courts of [Jurisdiction], and users
              consent to the jurisdiction of such courts.
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">18. Entire Agreement</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              These Terms and Conditions, along with the Privacy Policy, constitute the entire agreement between the users and Bisht Vision Pvt Ltd regarding
              the Levelit App.
            </li>
            <li className="mb-2">
              Any failure by the Company to enforce any right or provision of these Terms shall not be considered a waiver of such rights.
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">19. Severability</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              If any provision of these Terms is deemed invalid or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in
              full force and effect to the maximum extent permitted by law.
            </li>
          </ol>
          <h2 className="text-lg font-bold mt-6 mb-2">20. Contact Us</h2>
          <ol className="list-decimal list-inside ml-6">
            <li className="mb-2">
              If you have any questions or concerns about these Terms and Conditions, please contact us at:
              <ul className="list-disc list-inside ml-4">
                <li>Company Name: Bisht Vision Pvt Ltd</li>
                <li>Email: levelitofficiall@gmail.com</li>
                <li>Postal Address: [insert postal address]</li>
              </ul>
            </li>
          </ol>
          <p>
            Please review this Privacy Policy carefully and ensure that you understand its content. If you do not agree with any aspect of this Privacy Policy,
            please discontinue your use of the Levelit App.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
