import { useEffect } from "react";
import ContactUs from "./ContactUs";
import DarkModeToggle from "./DarkModeToggle";
import LandingPage from "./LandingPage";
import PrivacyPolicy from "./PrivacyPolicy";
import TermCondition from "./TermCondition";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./AboutUs";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    const handleSmoothScroll = (event) => {
      event.preventDefault();
      const target = event.target.getAttribute("href");
      const element = document.querySelector(target);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    const howItWorksLink = document.querySelector('nav a[href="#how-it-works"]');
    if (howItWorksLink) {
      howItWorksLink.addEventListener("click", handleSmoothScroll);
    }

    return () => {
      if (howItWorksLink) {
        howItWorksLink.removeEventListener("click", handleSmoothScroll);
      }
    };
  }, []);
  return (
    <div className="dark:bg-main">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms-and-condition" element={<TermCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
      <ToastContainer />
      <DarkModeToggle />
    </div>
  );
}

export default App;
