import React from "react";
import logo from "./assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer class="bg-white rounded-lg shadow dark:bg-neutral-800 mt-9">
      <div class="w-full max-w-screen-xl p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
          <div class="flex items-center mb-4 sm:mb-0">
            <Link to="/">
              <img src={logo} alt="logo" width="100" />
            </Link>
          </div>

          <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-white">
            <li>
              <div className=" mt-2 dark:text-secondary">
                <a href="https://www.facebook.com/profile.php?id=100092658005849&mibextid=ZbWKwL" className="mr-4" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebook} className="w-6 h-6 fill-current" />
                </a>
              </div>
            </li>
            <li>
              <div className=" mt-2 mr-3 dark:text-secondary">
                <a href="https://www.instagram.com/levelit.app/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="w-6 h-6 fill-current" />
                </a>
              </div>
            </li>
            <li>
              <Link to="/about-us" class="mr-4 hover:underline md:mr-6 ">
                About
              </Link>
            </li>
            <li>
              <Link to="/terms-and-condition" class="mr-4 hover:underline md:mr-6">
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" class="mr-4 hover:underline md:mr-6 ">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/contact-us" class="hover:underline">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <hr class="my-6 border-gray-200  dark:border-gray-700 lg:my-8" />
        <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 LevelIT . All Rights Reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
