import React from "react";

import NavBar from "./NavBar";
import Footer from "./Footer";

const AboutUs = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <NavBar />
      <section class="bg-white dark:bg-neutral-800 ">
        <div class="py-8 lg:py-16  mx-auto max-w-screen-md">
          <h2 class="mb-4 text-4xl tracking-wide font-extrabold text-center text-gray-900 dark:text-secondary">About Us</h2>
          <div class="container mx-auto py-8 px-4">
            <p class="mb-8 lg:mb-16   text-gray-500 dark:text-gray-400 sm:text-xl">
              Welcome to <span className="text-secondary">levelit</span>, the ultimate destination for creative minds and passionate creators! We're here to
              provide a platform where talent meets recognition, and where imagination knows no bounds. At levelit, we believe that every individual has a
              unique spark of creativity waiting to be discovered. Whether you're a meme master, a photography enthusiast, a short video wizard, or an artist in
              any other medium, this is your canvas to showcase your brilliance.
            </p>

            <h2 class="mb-4 text-2xl font-semibold text-center text-gray-800 dark:text-gray-200">Our Mission</h2>
            <p class="mb-8 lg:mb-16   text-gray-500 dark:text-gray-400 sm:text-xl">
              Our mission is simple: to empower creators and foster a vibrant community of like-minded individuals. We're dedicated to creating an environment
              where talent shines, where artistry is celebrated, and where dreams are brought to life.
            </p>

            <h2 class="mb-4 text-2xl font-semibold text-center text-gray-800 dark:text-gray-200">What Sets Us Apart</h2>
            <ul class="list-disc list-inside mb-8 lg:mb-16  text-gray-500 dark:text-gray-400 sm:text-xl">
              <li>
                Diverse Contests: Explore a wide range of contest categories, from hilarious memes to breathtaking photography and captivating short videos.
                There's something for every creative spirit.
              </li>
              <li>
                Fair Play: We're all about fairness. Our contests are built on a transparent voting system, ensuring that the best content rises to the top.
                It's your peers who decide the winners.
              </li>
              <li>
                Prizes and Recognition: Win amazing prizes and, more importantly, gain recognition for your talent. Your creations deserve to be seen and
                celebrated.
              </li>
            </ul>

            <h2 class="mb-4 text-2xl font-semibold text-center text-gray-800 dark:text-gray-200">How It Works</h2>
            <ol class="list-decimal list-inside mb-8 lg:mb-16  text-gray-500 dark:text-gray-400 sm:text-xl">
              <li>Create: Submit your masterpiece in the category that suits your talent best.</li>
              <li>Compete: Participate in contests, both free and paid, for a chance to win exciting prizes.</li>
              <li>Vote: Be a part of the judging panel by rating and voting for your favorite content.</li>
            </ol>

            <p class="mb-8 lg:mb-16    text-gray-500 dark:text-gray-400 sm:text-xl">
              <strong>Join Us Today</strong> Whether you're here to create, compete, or simply appreciate outstanding content, levelit is your home. Join us on
              this exciting journey of creativity, inspiration, and endless possibilities. Let's make magic together. Get started now!
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
